import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import BlogsImg from "../../components/images/web/blogs"

export default function Blogs() {
    return (
        <>
            <Layout>
                <SEO title="Blogs" />
                <Display
                    title="Blogs"
                    description="Blogging continues to be an effective way to build your online presence and attract new customers. No time to blog? Talk to us about packages suited to you and your small business budget. "
                    prev="/web/seo"
                    next="/web/social-media"
                    image={<BlogsImg />}
                />
            </Layout>                
        </>
    )
}
